<template>
  <div class="warningListExport">
    <Breadcrumb :title="$route.meta.title" />
    <section class="filterWrap">
      <!-- 時間 -->
      <div class="itemWrap">
        <label>查詢日期：</label>
        <el-date-picker :editable="false" type="daterange" value-format="yyyy-MM-dd" range-separator="至" 
          start-placeholder="開始日期" 
          end-placeholder="結束日期"
          v-model="rangeDate"
        ></el-date-picker>
      </div>
      <!-- 類型人名或組織 -->
      <div class="itemWrap">
        <label>名單類型：</label>
          <el-select v-model="listQuery.Type" placeholder="請選擇名單類型" no-data-text="目前無名單" clearable>
            <el-option v-for="item in typeList" :key="item.label"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
      </div>
      <!-- 名單狀態 -->
      <div class="itemWrap">
        <label>名單狀態：</label>
        <el-select v-model="listQuery.Status" multiple clearable placeholder="請選擇狀態" no-data-text="目前無名單">
          <el-option v-for="item in statusList" :key="item.id"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <!-- 名單類型 -->
      <div class="itemWrap">
        <label>名單屬性：</label>
        <el-select v-model="listQuery.Attribute" multiple clearable placeholder="請選擇名單" no-data-text="目前無名單">
          <el-option v-for="item in attributeSelectData" :key="item"
            :value="item"
            :label="item"
          ></el-option>
        </el-select>
      </div>
      <el-button @click="doSearch" type="primary" class="searchLog">查詢</el-button>
    </section>
    <div class="warningListExport__Content">
      <div class="warningListExport__listBox">
        <div class="warningListExport__listBox--header">
          <h2>警示名單彙總表</h2>
          <div class="exportWrap">
            <download-csv :data="CSVData" name="警示名單彙總表.csv"
              ><el-button type="primary">匯出</el-button>
            </download-csv>
          </div>
        </div>
        <div class="warningListExport__listBox--content">
          <el-table ref="multipleTable" style="width: 100%" border empty-text="暫無數據" max-height="400"
            :data="tableData"
          >
            <el-table-column type="index"  label="項次" width="80"></el-table-column>
            <el-table-column label="名單類型" prop="ListType" width="80">
              <template slot-scope="scope">{{ transListType(scope.row.ListType) }}</template>
            </el-table-column>
            <el-table-column label="名單狀態" prop="Status" width="100">
              <template slot-scope="scope">{{statusName(scope.row.Status)}}</template>
            </el-table-column>
            <el-table-column label="名單名稱" prop="Name" width="150">
              <template slot-scope="scope">
                <a class="link" @click="routerPushEditForm(scope.row)">{{ scope.row.Name }}</a>
              </template>
            </el-table-column>
            <el-table-column prop="Attribute" label="名單屬性">
              <template slot-scope="scope">
                  {{ attribute(scope.row.Attribute) }}
              </template>
            </el-table-column>
            <el-table-column label="建立時間">
              <template slot-scope="scope">
                <div>
                  {{ filterDate(scope.row.CreateDt) }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <Pagination
      @currentChange="pageChange"
      :pageSize="this.listQuery.Page_Size"
      :propsCurrentPage="this.listQuery.Page_Num"
      :totalLists="totalLists"
    ></Pagination>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
export default {
  components: {Breadcrumb,Pagination},
  data() {
    return {
      rangeDate: [],
      tableData: [],
      CSVData: [],
      attributeSelectData:[],
      totalLists: null,
      listQuery: {
        UserID: this.$store.state.userInfo.UserID,
        CreateCom: [this.$store.state.userInfo.ComName],//使用者的公司
        Attribute: [],//名單屬性
        CreateDept: [],//使用者的部門
        Type: [],//(單選)名單類別people||org
        Status: [],//(複選)名單狀態
        Start_Date: "",//查詢時間
        End_Date: "",//查詢時間
        Page_Size: 10, //每頁有幾筆
        Page_Num: 1, //目前頁數
        Status: [],//(複選)名單狀態
      },
      typeList: [
        { value: ["people"],label: "警示人名"},
        { value: ["org"], label: "警示組織"}
      ],
      statusList: [
        { id: 1, label: "編輯中", value: "edit" },
        { id: 2, label: "待覆核", value: "wait_approval" },
        { id: 3, label: "已覆核", value: "approval" },
        { id: 4, label: "已退回", value: "reject" },
        { id: 5, label: "待刪除", value: "wait_delete" },
        { id: 6, label: "已刪除", value: "delete" },
      ], 
    };
  },
  computed: {
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      };
    },
    transListType(){
      let text = "無";
      return (En) => {
        if(En==='people'){
          text = '人名'
        }else if(En==='org'){
          text = '組織'
        }
        return text 
      };
    },
    attribute(){
      let text = ''
      return (attribute) => {
        if(attribute.length>0){
          text = attribute.join("、")
        }else{
          text = '無'
        }
        return text
      };
    },
    statusName(){
      return(status)=>{
        let text = "";
        if(status==='edit'){
          text = "編輯中"
        }else if(status==='approval'){
          text = "已覆核"
        }else if(status==='reject'){
          text = "已退回" 
        }else if(status==='wait_approval'){
          text = "待覆核"
        }else if(status==='delete'){
          text = "已刪除"
        }else if(status==='wait_delete'){
          text = "待刪除"
        }
        return text
      }
    },
  },
  methods: {
    pageChange(val) {
      this.listQuery.Page_Num = val;
      this.getList();
    },
    routerPushEditForm(item) {
      this.$router.push({
        name: "warningListAdd",
        query: {
          caseStatus:item.Status,
          permission:item.Permission,
          warningFormType:item.ListType,
          listID:item.ListID,
        }
      });
    },
    doSearch(){
      this.$store.dispatch("loadingHandler", true);
      this.listQuery.Page_Num = 1;
      // this.getList();
      this.deleteNullValue();
      let temp = JSON.parse(JSON.stringify(this.listQuery))
      temp.IsUser = true;
      this.$api.list.searchList(temp).then((res) => {
        this.tableData = res.data;
        this.totalLists = res.total;
        this.$store.dispatch("loadingHandler", false);
      });
      this.getAllExportData();
    },
    getList() {
      this.$store.dispatch("loadingHandler", true);
      this.deleteNullValue();
      this.$api.list.searchList(this.listQuery).then((res) => {
        this.tableData = res.data;
        this.totalLists = res.total;
        this.$store.dispatch("loadingHandler", false);
      });
    },
    deleteNullValue() {
      if(!!this.rangeDate){
        if(this.rangeDate.length !== 0){
          this.listQuery.Start_Date = this.rangeDate[0]
          this.listQuery.End_Date = this.rangeDate[1]
        }
      }else{
        this.listQuery.Start_Date = "";
        this.listQuery.End_Date = "";
      }
      let keys = Object.keys(this.listQuery)
      keys.forEach((key)=>{
        if(Array.isArray(this.listQuery[key]) && this.listQuery[key].length===0){
          delete this.listQuery[key]
        }
        if(!this.listQuery[key]){
          delete this.listQuery[key]
        }
      })
    },
    getAttributeSelect(){
      this.$api.system.getOptions({Type:["ListAttribute"]}).then((res)=>{
        this.attributeSelectData = res.ListAttribute
      })
    },
    getAllExportData(){
      let requestData = {};
      requestData = JSON.parse(JSON.stringify(this.listQuery));
      requestData.Page_Num = 1;
      requestData.Page_Size = 9999;
      this.$api.list.searchList(requestData).then((res) => {
        let allData = res.data;
        let CSVData = [];
        allData.forEach((item) => {
          const { ListType, Name, CreateDt } = item;
          CSVData.push({ ListType, Name, CreateDt });
        });
        this.CSVData = CSVData;
      });
      
    }
  },
  mounted() {
    this.getList();
    this.getAllExportData();
    this.getAttributeSelect();
  },
};
</script>

<style lang="scss">
.warningListExport {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  &__Content{
    padding: 0px 1% 1% 1%;
  }
  &__listBox {
    &--header {
     text-align: center;
     h2{
       margin: 0px auto;
     }
     .exportWrap{
      width: 70px;
      text-align: right;
      margin-left: auto;
     }
    }
  }
  .filterWrap{
    padding: 1%;
    .itemWrap{
      margin-bottom: 10px;
      width: 500px;
      display: flex;
      align-items: center;
      label{
        width:16%;
      }
      &:nth-child(2),&:nth-child(3){
        .el-select{
          width: 50%;
        }
      }
    }
  }
  a{
    color: black;
    text-decoration:none;
    &:hover{
      text-decoration:none;
      color:blue
    };
    cursor: pointer;
    
  }
}
</style>
